import React from 'react';
import PropTypes from 'prop-types';
import './button.css';

const ButtonCta = (props) => (
  <a
    type="button"
    className={`button--cta bg-blue-light40 ${props.className}`}
    href={props.url}
    role="button"
    target="_blank"
    rel="noreferrer"
    onClick={(e) => {
      if (props.promoCode) {
        if (appWebView) {
          e.preventDefault();
          appWebView.postMessage(props.promoCode);
        }
      }
    }}
  >
    {props.text}
  </a>
);

ButtonCta.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  promoCode: PropTypes.string,
  className: PropTypes.string,
};

ButtonCta.defaultProps = {
  promoCode: null,
  className: '',
};

export default ButtonCta;
