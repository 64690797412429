import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import CONTACT_CONFIG from '../configs/contactConfig';

const { PT_CONTACT, DIREKTORAT_CONTACT } = CONTACT_CONFIG;

const CardPengaduan = () => (
  <div className="container mx-auto">
    <div className="box-service text-center grid justify-center lg:text-left py-8 lg:py-0 lg:my-10 lg:grid lg:grid-cols-4">
      <div className="self-center max-w-xs lg:ml-12">
        <p className="font-bold lg:text-2xl">
          Layanan Pengaduan Konsumen
        </p>
      </div>
      <div className="leading-relaxed self-center max-w-xs">
        <p className="font-bold">PT. Komunal Sejahtera Indonesia</p>
        <p>Telepon : {PT_CONTACT.PHONE}</p>
        <p>WhatsApp : {PT_CONTACT.WHATSAPP}</p>
        <p>Email : {PT_CONTACT.EMAIL}</p>
      </div>
      <div className="pt-4 lg:pt-0 leading-relaxed self-center max-w-xs">
        <p className="font-bold">Direktorat Jenderal Perlindungan Konsumen dan Tertib Niaga Kementerian Perdagangan RI</p>
        <p>WhatsApp : {DIREKTORAT_CONTACT.WHATSAPP}</p>
      </div>
      <div className="max-w-xs">
        <StaticImage
          src="../images/woman2.png"
          alt="woman"
          objectFit="contain"
          className="lg:w-44 w-64 mx-auto flex mt-3 lg:mt-0"
          placeholder="none"
        />
      </div>
    </div>
  </div>
);

export default CardPengaduan;
