import React from 'react';

const NoScript = () => (
  <>
    <noscript>
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-W2652NZ"
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
        title="gtm"
      />
    </noscript>
    <noscript>
      <img
        height={1}
        width={1}
        style={{ display: 'none' }}
        src="https://www.facebook.com/tr?id=1022080105295784&ev=PageView&noscript=1"
        alt="-"
      />
    </noscript>
    <noscript>
      <img
        height="1"
        width="1"
        style={{ display: 'none' }}
        alt="linkedin-ads"
        src="https://px.ads.linkedin.com/collect/?pid=3584460&fmt=gif"
      />
    </noscript>
  </>
);

export default NoScript;
