import React from 'react';
import PropTypes from 'prop-types';

export default function Wrapper({ children }) {
  return (
    <div className="h-[calc(100dvh)] overflow-y-auto">
      {children}
    </div>
  );
}

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
