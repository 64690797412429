import React from 'react';
import propTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StaticImage } from 'gatsby-plugin-image';
import {
  // faYoutube,
  faFacebook,
  faTwitter,
  // faInstagram,
  faLinkedin,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
// import facebookLogo from '../../images/facebook-blog.png';
import './button.css';

export const socialAccount = [
  {
    name: 'facebook',
    url: 'https://facebook.com/share.php?u=',
    icon: faFacebook,
  },
  {
    name: 'linkedin',
    url: 'https://www.linkedin.com/sharing/share-offsite/?url=',
    icon: faLinkedin,
  },
  {
    name: 'twitter',
    url: 'https://twitter.com/home/?status=',
    icon: faTwitter,
  },

  {
    name: 'whatsapp',
    url: 'https://api.whatsapp.com/send?text=',
    icon: faWhatsapp,
  },
];

const ButtonShareMobile = (props) => (
  <div className="flex flex-row justify-center lg:hidden md:hidden w-full items-center">
    <StaticImage className="text-primary ml-1 mr-2" width="20" src="../../images/share.svg" alt="share" />
    <h4 className="text-primary font-bold">Bagikan</h4>
    {socialAccount.map((sosmed) => (
      <a
        href={`${sosmed.url}${props.url}`}
        className="icon-social-media"
        target="_blank"
        rel="noopener noreferrer"
        aria-label={sosmed.name}
      >
        <FontAwesomeIcon icon={sosmed.icon} className="text-white" size="3x" />
      </a>
    ))}
  </div>
);

ButtonShareMobile.propTypes = {
  url: propTypes.string,
};

ButtonShareMobile.defaultProps = {
  url: '',
};

export default ButtonShareMobile;
